import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';

import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../../../environments/environment';
import { Database } from './database.types';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  readonly client: SupabaseClient<Database>;

  constructor() {
    this.client = createClient(environment.supabaseUrl, environment.supabaseKey);
    // @ts-expect-error - Expose Supabase client to window for debugging
    window.supabase = this.client;
  }

  generateId() {
    return uuidv4();
  }
}
