export const environment = {
  baseUrl: '',
  api: '/api/v2',
  env: 'production',
  production: true,
  stripePublishableKey: 'pk_live_JM5pSvm6deso8IkHsSfP2Edy00ulAf4ryx',
  supabaseUrl: 'https://wnlmzhxbiwktciqndfdl.supabase.co',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndubG16aHhiaXdrdGNpcW5kZmRsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTIyMTQ0MjAsImV4cCI6MjAwNzc5MDQyMH0.SFIWSFFIXtwai0DKKviCPMed63YVbNZZMMBQ_4CWWPI',
  syncfusionLicenseKey:
    'ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5SdkZiWn9bdHxVT2Fc',
};
